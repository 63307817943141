<template>
  <div>
    <el-row>
      <div class="wraper" v-wechat-title="this.goodsinfo.name+ '-3A医药城'">
        <ShopHead :productName="productName"></ShopHead>
        <FlashGroupGalss class="glass"  v-if="renderFlag"  :goodsinfo="goodsinfo"></FlashGroupGalss>
        <FlashGroupInfo
          class="info"
          v-if="renderFlag"
          :goodsinfo="goodsinfo.promotionProductRelationVO"
            :arrGoods="goodsinfo"
        ></FlashGroupInfo>
      </div>
    </el-row>
    <el-row>
      <el-col :span="24">
        <FlashGroupDetailInfo
          class="detailInfo"
          v-if="renderFlag"
          :goodsinfo="goodsinfo.promotionProductRelationVO"
           :arrGoods="goodsinfo"
        ></FlashGroupDetailInfo>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { GetFlashGroupDetail } from "api/product.js";
// import Qs from 'qs'
const FlashGroupGalss = () => import("components/product/FlashGroupGalss.vue");
const FlashGroupInfo = () => import("components/product/FlashGroupInfo.vue");
const ShopHead = () => import("components/product/ShopHead.vue");
const FlashGroupDetailInfo = () => import("components/product/FlashGroupDetailInfo.vue");
export default {
  name: "FlashGroupDetail",
   inject:['reload'],
  data() {
    return {
      productName:'',
      renderFlag: false,
      AssociatedFlag:true,
      goodsinfo: "",
      parameterValues: "",
    };
  },
  components: {
    FlashGroupGalss,
    FlashGroupInfo,
    ShopHead,
    FlashGroupDetailInfo,
  },
   watch: {
    //   监听路径变化，获取数据并给商品数据赋值
    "$route.query.id": function() {
        this.reload();
    },
  },
  created() {
   
    this.getProductDetail();
  },
  methods: {
    AssociatedFun(){
      this.AssociatedFlag=false
    },
    getProductDetail() {
      const parmes = this.$route.query;
       // 显示loading
     this.$loading({ fullscreen: true ,background:'#ffffff45'})
      GetFlashGroupDetail(parmes).then((res) => {
        // if(res.data.code==400){
        //   this.$message.error(res.data.msg);
        //   let that=this
        //   setTimeout(function(){that.$router.push({name:"Home"})},500);
        // }
        this.productName=res.data.data.name
        this.goodsinfo = res.data.data;
        this.renderFlag = true;
        this.AssociatedFlag=true;
        this.parameterValues = res.data.data;
         // 关闭loading
          this.$loading().close();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.el_main {
  background: #fff;
}
.wraper {
  margin-top: 20px;
  .glass {
    float: left;
  }
  .info {
    float: left;
    margin-left: 20px;
    width: calc(100% - 458px);
  }
  .detailInfo {
    width: 1200px;
  }
}
</style>
